/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridDis{
        /* background-color: aqua; */
        padding-inline-start: 3%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadDis{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-top: 2%;
        text-align: center;
    }

    #segmentDis{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-bottom: 7%; */
    }

    #scrollDis{
        overflow-x: hidden !important;
        height: 60vh;
        overflow-y: 'scroll';
        padding-inline-start: 10%;
        padding-inline-end: 10%;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    #rowDis{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #firstParagraph{
        font-size: 17px;
        padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        /* text-align: justify; */
        hyphens: auto;
        line-height: 25px;
        text-justify: distribute;
        word-spacing: -2px;
        /* word-break: break-all; */
      }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #fontHeadDis{
      font-family: 'Mitr', sans-serif;
      font-size: 2.5vw;
      font-weight: 400;
      text-align: center;
    }
  
    #gridDis{
      margin-top: 5%;
      margin-left: 10%;
    }
  
    #segmentDis{
      /* background-color: aqua; */
      width: 80vw;
      height: 80vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 60vh;
      overflow-y: 'scroll';
      padding-inline-start: 5%;
      padding-inline-end: 5%;
      padding-top: 3%;
      padding-bottom: 3%;
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
    }
  
    #loadSegment{
      width: fit-content;
    }

    #rowDis{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #firstParagraph{
        font-size: 17px;
        padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        text-align: justify;
        hyphens: auto;
        line-height: 25px;
        text-justify: distribute;
        word-spacing: -3px;
        /* word-break: break-all; */
      }
}
  
/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #fontHeadDis{
      font-family: 'Mitr', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin: 5%;
    }
  
    #gridDis{
      /* height: 80vh; */
      padding-left: 5%;
      display: flex;
      align-items: center;
    }
  
    #segmentDis{
      /* background-color: aliceblue; */
      width: 90vw;
      height: 80vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 67vh;
      overflow-y: 'scroll';
      /* padding: 2% ; */
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
      padding-inline-start: 5%;
      padding-inline-end: 5%;
      padding-top: 3%;
      padding-bottom: 3%;
    }

    #firstParagraph{
        font-size: 17px;
        padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 10%;
        /* text-align: justify; */
        hyphens: auto;
        line-height: 25px;
        /* word-break: break-all; */
      }
  }

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #fontHeadDis{
        font-family: 'Mitr', sans-serif;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        margin: 5%;
    }
  
    #gridDis{
      /* background-color: aqua; */
      height: 80vh;
      height: 70vh; 
      z-index: 1;
      margin-top: 13%;      
      margin-left: 8%; 
    }
  
    #segmentDis{
      /* background-color: aliceblue; */
      width: 80vw;
      height: 70vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 50vh;
      overflow-y: 'scroll';
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
      padding-inline-start: 10%;
      padding-inline-end: 10%;
      padding-top: 5%;
      padding-bottom: 3%;
    }

    #firstParagraph{
        font-size: 15px;
        padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 14px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        /* text-align: justify; */
        hyphens: auto;
        line-height: 25px;
        /* word-spacing: -8px; */
      }
  }
  