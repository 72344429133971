@import url(https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
    display: -webkit-flex;
    display: flex;
    font-family: 'Mitr', sans-serif;
    /* min-height: 100vh; */
    display: flex;
   
}
  
body, html, .App, #root {
    width: 100%;
    height: 100%;
}

.kep-login-facebook.metro{
    border-radius: 15px;
    font-family: 'Mitr';
    font-weight: 400;
    box-shadow: 0px 3px 10px #88888879;
    /* background-color: #3b5998; */
}

/* #### Desktops #### */
@media screen and (min-width: 900px){
    /* some CSS here */
    #background{
        background-image: url(/static/media/webBGTest.dbe5661c.png);
        /* background-color: #0f8970; */
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        -webkit-justify-content: center;
                justify-content: center;
    }
    
    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 8vw;
        color: #0f8970; 
    }
    
    #psyjaiContainner{
        margin-top: 20vw;
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
    }
    
    .signInbutton{
        font-family: 'Nunito', sans-serif;
        color: white;
        background-color: #3b5998;
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        box-shadow: 0px 3px 10px #88888879;
        
    }
   
}



/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 480px){
    /* some CSS here */
    #background{
        background-image: url(/static/media/psyjaiPhoneBG.befe2949.png);
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        -webkit-justify-content: center;
                justify-content: center;
    
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 18vw;
        color: #0f8970;
        -webkit-justify-content: center;
                justify-content: center;
    }
    
    #psyjaiContainner{
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        text-align: center;
        margin-top: 30vh;
    }
    
    #signInbutton{
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        box-shadow: 0px 3px 10px #88888879;
      
    }
}

/* #### Mobile Phones Landscape #### */
@media screen and (min-width: 480px) and (max-width: 900px) and (orientation: landscape){
    /* some CSS here */
    #background{
        background-image: url(/static/media/psyjaiPhoneland.1c11817b.png);
        /* background-color: #0f8970; */
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        -webkit-justify-content: center;
                justify-content: center;   
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 10vw;
        color: #0f8970;
        -webkit-justify-content: center;
                justify-content: center;
    }
    
    #psyjaiContainner{
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        text-align: center;
        margin-top: 25vh;
    }
    
    #signInbutton{
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        box-shadow: 0px 3px 10px #88888879;
      
    }
}


/* #### Tablets Portrait or Landscape #### */
@media screen and (min-width: 480px) and (max-width: 900px) and (orientation: portrait){
    /* some CSS here */
    #background{
        background-image: url(/static/media/psyjaiTabletport.e2ff88b0.png);
        /* background-color: #0f8970; */
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        -webkit-justify-content: center;
                justify-content: center;
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 12vw;
        color: #0f8970;
        -webkit-justify-content: center;
                justify-content: center;
    }
    
    #psyjaiContainner{
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
        margin-top: 30vw;
    }
    
    #signInbutton{
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        box-shadow: 0px 3px 10px #88888879;
        width: 10px;
        height: 300px;
      
    }
  }

body {
    display: -webkit-flex;
    display: flex;
    font-family: 'Mitr', sans-serif;
    height: 100vh;
    display: inline-block;
  }
  
body, html, .App, #root {
    width: 100%;
    height: 100%;
    font-family: 'Mitr', sans-serif;
}
.container-fluid{
  padding-left: 0;
  padding-right: 0;
}
#pad{
    margin-left: 5%;
}

#modalProfile{  
  border-radius: 5vw; 
  text-align: center;
}

.ui.active.modal{
  position: -webkit-sticky;
  position: sticky;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#messageHeadModal{
  color: #386A48;
  font-family: 'Mitr', sans-serif;
  font-weight: 400;
  /* font-size: 1.2vw; */
  text-align: center;
  padding: 5%;
 }

#buttonPrize{
    font-family: 'Mitr', sans-serif;
    text-align: center;
    margin: 4%;
    border: 0;
    background-color: #FFD167;
    color: #935A10;
    border-radius: 20px;
}

#font{
  font-family: 'Mitr', sans-serif;
  box-shadow: 0px 5px 7px #79797988;
}

#fontPopup{
  font-family: 'Mitr', sans-serif;
}

#head-name{
  font-size: 1.5vw;
  font-family: 'Nunito', sans-serif;
  padding-top: 20px;
  color: #386A48;
  font-weight: 700;
}


#fontMenu{
  font-family: 'Mitr', sans-serif;
  font-size: 18px;
  color: #386A48;
  margin-left: 5px;
}

#menuActive{
  background-color: #688B73;
  color: #fff;
  font-weight: 500;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}

#menuInActive{
  color: #538061;
  font-weight: 500;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}

#lastMenuActive{
  background-color: #688B73;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}


.popupDash.ui.popup:before{
  /* background: #CD5275 !important; */
  background: #ffffff !important;
}

#modalDesTree{
  padding: 2%;
}



/*  background on mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
  /* sidebar */
  #sideBarMobile{
    background-color: #DBFAA4;
    height: 101.5vh;
    width: 50vw;
    display: block;
    z-index: 5;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 3%;
    position: absolute;
    overflow-y: scroll;
    transition: .3s ease all;
  }

  #itemSide{
    /* background-color: #935A10; */
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: #386A48;
    margin-bottom: 5%;
  }
  
  #containnerMobile{
    margin-top: 5%;
    margin-left: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
  }

  #backgroundDash{
    background-image: url(/static/media/psyjaiDashPhonePort.73c04a03.png);
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute'; 
  }

  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 40vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;  
    z-index: 4;
  }

  #gridCard{
    padding-top: 14%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
   
  }

  #gridCalender{
    /* background-color: rgba(0, 128, 0, 0.253); */
    width: 100%;
    height: 80vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
    
  #calendar{ 
    width: 85vw;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 3%;

  }

    .fc{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
      width: 79vw;
      height: 70vh;
      
      /* rewrite button in calendar  */
      --fc-button-bg-color: #567e63;
      --fc-button-border-color: #567e63;
      --fc-button-hover-bg-color: #567e63;
      --fc-button-hover-border-color: #567e63;
      --fc-button-active-bg-color: #567e63;
      --fc-button-active-border-color: #567e63;
  
      /* rewrite color today */
      --fc-today-bg-color: #EEDBC9;
    }
  
    .fc-day a{
      color: #386A48;
    }
  
    .fc-header-toolbar{
      padding-top: 10px;

    }
  
    .fc-toolbar-title{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
    }
  
    #gridTreeDash{
      /* background-color: rgba(255, 255, 0, 0.247); */
      /* padding-left:vw ; */
      padding-top: 6vw;   
      display: -webkit-flex;   
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; 
      -webkit-align-items: center; 
              align-items: center;
    } 
  
    #emotionContainner{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }

    .fc-h-event .fc-event-main {
      /* background-color: #386A48; */
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }
  
    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
      /* background-color: #386A48; */
      /* height: 3vw; */
   }

    #imageEmotion{
      /* width: 4vw;
      height: 4vw; */
      -webkit-align-self: center;
              align-self: center;
      justify-self: center;
    }
  
  
    #imgTree{
      /* justify-self: flex-end;   */
      width: 100vh; 
      /* height: 45vh;  */
    }
  
    #messageCard{
      border-radius: 100vw;
      width: 70vw;
      /* height: 5vh; */
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;  
      padding: 8vw;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #messageTree{
      color: #386A48;
      font-family: 'Mitr', sans-serif;
      font-weight: 400;
      font-size: 3.5vw;
      text-align: center;
      padding: 2%;
    }
  
    #messageNameTree{
      border-radius: 100vw;
      /* background-color: #EFFCD1; */
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #labelTree{
      background-color: #567e63;
      width: 2vw;
      color: #fff;
      font-size: 3vw;
      margin-right: 30vw;
      box-shadow: 0px 2px 7px #79797988;
      /* margin-bottom: 2%; */
    }
  
    #textTree{
      border: 0;
      width: 65vw;
      height: 65vw;
      border-radius: 5vw;
      box-shadow: 0px 5px 7px #79797988;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      /* padding: 1.6vw; */
      /* align-items: center; */
      /* opacity: 0.20; */
    }
  

}

/*  background on mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
  #sideBarMobile{
    background-color: #DBFAA4;
    height: 101.5vh;
    width: 30vw;
    display: block;
    z-index: 5;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 3%;
    position: absolute;
    overflow-y: scroll;
    transition: .3s ease all;
  }

  #itemSide{
    /* background-color: #935A10; */
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: #386A48;
    margin-bottom: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  #containnerMobile{
    margin-top: 2%;
    margin-left: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
  }
  #backgroundDash{
    background-image: url(/static/media/psyjaiDashPhoneLand.becc625b.png);
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute';   
  }

  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 20vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40%;
    padding-left: 10%;
    z-index: 3;
    
  }

  #gridCard{
    /* background-color: rgba(24, 24, 255, 0.836); */
    padding-top: 10%;
    width: 100%;
    height: 90vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  #gridCalender{
    /* background-color: rgba(0, 128, 0, 0.253); */
    width: 100%;
    padding-left: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 2;
  }
    
  #calendar{ 
    width: 100%;
    /* height: 120vh; */
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    /* padding: 2%; */

  }
  
    .fc{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
      width: 100%;
      height: 120vh; 
      padding: 2%;
      /* rewrite button in calendar  */
      --fc-button-bg-color: #567e63;
      --fc-button-border-color: #567e63;
      --fc-button-hover-bg-color: #567e63;
      --fc-button-hover-border-color: #567e63;
      --fc-button-active-bg-color: #567e63;
      --fc-button-active-border-color: #567e63;
  
      /* rewrite color today */
      --fc-today-bg-color: #EEDBC9;
    }
  
    .fc-day a{
      color: #386A48;
    }
  
    .fc-header-toolbar{
      padding-top: 10px;
    }
  
    .fc-toolbar-title{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
    }

    .fc-scroller {
      overflow-y: hidden !important;
    } 
  
    #gridTreeDash{
      /* background-color: rgba(255, 255, 0, 0.247); */
      /* padding-left:vw ; */
      padding-top: 6vw;   
      display: -webkit-flex;   
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; 
      -webkit-align-items: center; 
              align-items: center;
    } 
  
    #emotionContainner{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
      /* background-color: #386A48; */
      /* height: 3vw; */
   }
  
    .fc-h-event .fc-event-main {
      /* background-color: #386A48; */
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }
  
    #imageEmotion{
      width: 4vw;
      height: 4vw;
      -webkit-align-self: center;
              align-self: center;
      justify-self: center;
    }
  
  
    #imgTree{
      /* justify-self: flex-end;   */
      width: 100vh; 
      /* height: 45vh;  */
    }
  
    #messageCard{
      border-radius: 100vw;
      width: 30vw;
      /* height: 22vh; */
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;  
      padding: 3vw;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #messageTree{
      color: #386A48;
      font-family: 'Mitr', sans-serif;
      font-weight: 400;
      font-size: 1.8vw;
      text-align: center;
      padding: 2%;
    }
  
    #messageNameTree{
      border-radius: 100vw;
      /* background-color: #EFFCD1; */
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #labelTree{
      background-color: #567e63;
      width: 2vw;
      color: #fff;
      font-size: 1.5vw;
      margin-right: 30vw;
      box-shadow: 0px 2px 7px #79797988;
      /* margin-bottom: 2%; */
    }
  
    #textTree{
      border: 0;
      width: 30vw;
      height: 30vw;
      border-radius: 5vw;
      box-shadow: 0px 5px 7px #79797988;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      /* padding: 1.6vw; */
      /* align-items: center; */
      /* opacity: 0.20; */
    }
  
}


/* Medium devices (lanscape tablet and computer) */
@media only screen and (min-width: 900px){
  #containnerComputer{
    height: 100vh;
  }

  #backgroundDash{
    background-image: url(/static/media/psyjaiDashLandscape.c048172b.png);
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;    
    -webkit-justify-content: center;    
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 6%;
  }

  #gridMenu{
    /* background-color: red; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    /* padding-left: 20%; */
    -webkit-align-items: center;
            align-items: center;
  }

  #gridDashboard{
    /* padding-inline: 5%; */
    -webkit-padding-start: 5%;
            padding-inline-start: 5%;
    height: 80vh;
    width: 80vw;
  }

  #gridCard{
    /* background-color: blue; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; 
  }

  #logout{
    border: 0;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 10px;
    font-family: 'Mitr', sans-serif;
    font-weight: 500;
    background-color: #fff;
    color: rgb(54, 54, 54);
    box-shadow: 0px 5px 7px #79797988;
  }
  
  /* css calendar */
  #gridCalender{
    /* background-color: green; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 80vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
  }

  #calendar{ 
    width: 50vw;
    /* height: 75vh; */
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 2vw;
  }

  .fc{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    width: 45vw; 
    height: 70vh;
    
    /* rewrite button in calendar  */
    --fc-button-bg-color: #567e63;
    --fc-button-border-color: #567e63;
    --fc-button-hover-bg-color: #567e63;
    --fc-button-hover-border-color: #567e63;
    --fc-button-active-bg-color: #567e63;
    --fc-button-active-border-color: #567e63;
    
    /* rewrite color today */
    --fc-today-bg-color: #EEDBC9;
    
  }
  .fc .fc-daygrid-day.fc-day-today{
    /* background-color: #fcfcfc; */
    /* border-color: #386A48; */
  }

  .fc-day a{
    color: #386A48;
  }

  .fc-header-toolbar{
    padding-top: 10px;
  }

  .fc-toolbar-title{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
     /* background-color: #386A48; */
     /* height: 3vw; */
  }

  #imageEmotion{
    width: 4vw;
    height: 4vw;
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
  }
  
  .fc-h-event .fc-event-main {
    /* background-color: #ac917cdc; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  td {
    /* height: 20px; */
  }

  #gridTreeDash{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  #emotionContainner{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #386A48;
    height: 4vh;
  }

  
  #imgTree{
    /* justify-self: flex-end;   */
    width: 90vh; 
    /* height: 45vh;  */
  }

  #messageCard{
    border-radius: 100vw;
    width: 20vw;
    /* height: 10vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;  
    padding: 2.5vw;
    box-shadow: 0px 5px 7px #79797988;
  }

  #messageTree{
    color: #386A48;
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    padding: 2%;
  }

  #messageNameTree{
    border-radius: 100vw;
    /* background-color: #EFFCD1; */
    box-shadow: 0px 5px 7px #79797988;
  }

  #labelTree{
    background-color: #567e63;
    width: 2vw;
    color: #fff;
    font-size: 1vw;
    margin-right: 30vw;
    box-shadow: 0px 2px 7px #79797988;
    /* margin-bottom: 2%; */
  }

  #textTree{
    border: 0;
    width: 19vw;
    height: 19vw;
    border-radius: 5vw;
    box-shadow: 0px 5px 7px #79797988;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }

 #rowAvatar{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    padding: 2%;
 }

 #messageHeadModal{
  color: #386A48;
  font-family: 'Mitr', sans-serif;
  font-weight: 400;
  /* font-size: 1.2vw; */
  text-align: center;
  padding: 5%;
 }

 #imageAvatarActive{
    background-color: #DBFAA4;
    border-radius: 20vw;
    padding: 2%;  
    width: -webkit-fit-content;  
    width: -moz-fit-content;  
    width: fit-content;
 }

 #imageAvatar{
  /* background-color: #DBFAA4; */
  border-radius: 20vw;
  padding: 2%;  
  width: -webkit-fit-content;  
  width: -moz-fit-content;  
  width: fit-content;
}

#imgLoading{
  /* background-color: #386A48; */
  width: 16vw;
  height: 16vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* position: absolute; */
}

#buttonChange{
  /* width: 12.8vw; */
  display: -webkit-flex;
  display: flex;
  /* background-color: #c0e4cb; */
  padding: 0;
  /* position: absolute; */
  z-index: 1;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

#nameContainner{
  /* background-color: #386A48; */
  width: 20vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
 #buttonModal{
  font-weight: 400;
  border-radius: 2vw; 
  font-family: 'Mitr', sans-serif;
 }
} 


/* Medium devices (portrait tablet) */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
  #containnerTablet{
    height: 100vh;
    position: absolute; 
  }

  #backgroundDash{
    background-image: url(/static/media/psyjaiDashLandscape.c048172b.png);
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute'; 
  }

  /* rewrite */
  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: 10%;
    padding-left: 10%;
    z-index: 3;
    /* position: absolute; */
  }

  #gridCard{
    /* background-color: rgba(24, 24, 255, 0.836); */
    width: 100vw;
    margin-left: 12%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center;
    z-index: 2;
    /* position: relative; */
  }

  #gridCalender{
  /* background-color: rgba(0, 128, 0, 0.253); */
    height: 80vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 2;
    /* position: relative; */
  }
  
  #calendar{ 
    width: 80vw;
    height: 80vw;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 2vw;
  }

  .fc{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    width: 55vw; 
    height: 65vh;
    
    /* rewrite button in calendar  */
    --fc-button-bg-color: #567e63;
    --fc-button-border-color: #567e63;
    --fc-button-hover-bg-color: #567e63;
    --fc-button-hover-border-color: #567e63;
    --fc-button-active-bg-color: #567e63;
    --fc-button-active-border-color: #567e63;

    /* rewrite color today */
    --fc-today-bg-color: #EEDBC9;
  }

  .fc-day a{
    color: #386A48;
  }

  .fc-header-toolbar{
    padding-top: 10px;
  }

  .fc-toolbar-title{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

   #gridTreeDash{
    /* background-color: rgba(255, 255, 0, 0.247); */
    /* padding-left:vw ; */
    padding-top: 6vw;   
    display: -webkit-flex;   
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; 
    -webkit-align-items: center; 
            align-items: center;
    position: relative;
  } 

  #emotionContainner{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .fc-h-event .fc-event-main {
    /* background-color: #386A48; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    /* background-color: #386A48; */
    /* height: 3vw; */
 }

  #imageEmotion{
    /* width: 4vw;
    height: 4vw; */
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
  }


  #imgTree{
    /* justify-self: flex-end;   */
    width: 100vh; 
    /* height: 45vh;  */
  }

  #messageCard{
    border-radius: 100vw;
    width: 25vw;
    /* height: 10vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;  
    padding: 3vw;
    text-align: center;
    box-shadow: 0px 5px 7px #79797988;
  }

  #messageTree{
    color: #386A48;
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 1.8vw;
    text-align: center;
    padding: 2%;
  }

  #messageNameTree{
    border-radius: 100vw;
    /* background-color: #EFFCD1; */
    box-shadow: 0px 5px 7px #79797988;
  }

  #labelTree{
    background-color: #567e63;
    width: 2vw;
    color: #fff;
    font-size: 1.2vw;
    margin-right: 30vw;
    box-shadow: 0px 2px 7px #79797988;
    /* margin-bottom: 2%; */
  }

  #textTree{
    border: 0;
    width: 24vw;
    height: 24vw;
    border-radius: 5vw;
    box-shadow: 0px 5px 7px #79797988;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    /* padding: 1.6vw; */
    /* align-items: center; */
    /* opacity: 0.20; */
  }

} 
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridMood{
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;  
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        margin-inline: 10%;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #segmentMood{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        -webkit-align-items: center;
                align-items: center;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
      
        /* padding: 2% ; */
    }

    #centered{
        display: 'flex'; 
        -webkit-justify-content: 'center'; 
                justify-content: 'center';
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridMood{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        margin-inline: 4.8%;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: -webkit-flex;
        display: flex;
        /* flex-direction: column; */
        -webkit-justify-content: center;
                justify-content: center;
    }

    #segmentMood{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 50vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridMood{
        margin: 0;
        height: 100%;
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        -webkit-margin-start: 4.8%;
                margin-inline-start: 4.8%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentMood{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        /* padding: 2% ; */
    }    

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridMood{
        height: 70vh; 
        margin-top: 13%;      
        margin-left: 8%; 
        display: -webkit-flex; 
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; 
        -webkit-align-items: center; 
                align-items: center;
        z-index: 1;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        /* margin-inline: 4.8%; */
        /* background-color: antiquewhite; */
        width: 40vw;
        margin: 4%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentMood{
        width: 80vw;
        height: 65vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 1.8vw;
    font-weight: 400;
    margin-left: 3%;
  }

  #gridIntervention{
    /* background-color: aqua; */
    /* padding-inline: 5%; */
    -webkit-padding-start: 5%;
            padding-inline-start: 5%;
    height: 80vh;
    width: 80vw;
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 76vh;
    overflow-y: 'scroll';
    padding: 2% ;
  }

  #loadSegment{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .ui.popup:before {
    /* background: #CD5275 !important; */
    background: #CD5275 !important;
  }

  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 2.5vw;
    font-weight: 400;
  }

  #gridIntervention{
    margin-top: 5%;
    margin-left: 10%;
  }

  #segmentIntervention{
    /* background-color: aqua; */
    width: 80vw;
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 70vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #popup{
    /* border: 0; */
    /* opacity: 1;
    padding: '1em'; */
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}

}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 4vw;
    font-weight: 400;
  }

  #gridIntervention{
    /* height: 80vh; */
    padding-left: 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /* background-color: aliceblue; */
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    width: 90vw;
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 76vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
    padding-top: 4%;
    padding-left: 2%;
  }

  #gridIntervention{
    /* background-color: aqua; */
    height: 80vh;
    height: 70vh; 
    z-index: 1;
    margin-top: 13%;      
    margin-left: 8%; 
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    width: 80vw;
    height: 70vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 65vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}
}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridHomework{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadHomework{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
    }

    #segmentHomework{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollHomework{
        overflow-x: hidden !important;
        height: 76vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridHomework{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadHomework{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentHomework{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollHomework{
        overflow-x: hidden !important;
        height: 76vh;
        overflow-y: 'scroll';
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridHomework{
        /* margin-left: 2%; */
        padding-left: 5%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }

    #fontHeadHomework{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentHomework{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollHomework{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridHomework{
        /* height: 80vh; */
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%; 
    }

    #fontHeadHomework{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentHomework{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollHomework{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridResult{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadResult{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
        /* padding-left: 2%; */
    }

    #segmentResult{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollResult{
        overflow-x: hidden !important;
        height: 76vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridResult{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadResult{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentResult{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollResult{
        overflow-x: hidden !important;
        height: 55vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridResult{
        padding-left: 5%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }

    #fontHeadResult{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentResult{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollResult{
        overflow-x: scroll !important;
        height: 65vh;
        overflow-y: scroll;
        /* padding: 2% ; */
    }

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridResult{
        /* height: 80vh; */
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%; 
    }

    #fontHeadResult{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentResult{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollResult{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridTree{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
        /* padding-left: 2%; */
    }

    #segmentTree{
        width: 70vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 76vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridTree{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentTree{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 55vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridTree{
        padding-left: 5%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentTree{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollTree{
        overflow-x: scroll !important;
        height: 65vh;
        overflow-y: scroll;
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridTree{
        /* height: 80vh; */
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%; 
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentTree{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridComment{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadComment{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
    }

    #segmentComment{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollComment{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowCom{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #btnSaveComment{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridComment{
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%; 
    }

    #fontHeadComment{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentComment{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollComment{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowCom{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #btnSaveComment{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridComment{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadComment{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentComment{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollComment{
        overflow-x: hidden !important;
        height: 50vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
      
    }

    #nameHeaderComment{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }

    #rowCom{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #btnSaveComment{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
        margin-bottom: 2%;
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridComment{
        padding-left: 5%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }

    #fontHeadComment{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentComment{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollComment{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowCom{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #btnSaveComment{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
        margin-bottom: 2%;
    }
}

/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridQA{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadQA{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
    }

    #segmentQA{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 5%;
    }

    #scrollQA{
        overflow-x: hidden !important;
        height: 90%;
        overflow-y: 'scroll';
        padding: 2% ;
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridQA{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadQA{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentQA{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollQA{
        overflow-x: hidden !important;
        height: 50vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridQA{
        padding-left: 5%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;

    }

    #fontHeadQA{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentQA{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollQA{
        overflow-x: scroll !important;
        height: 65vh;
        overflow-y: 'scroll';
    }

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridQA{
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%;   
    }

    #fontHeadQA{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentQA{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        -webkit-padding-start: 4%;
                padding-inline-start: 4%;
    }

    #scrollQA{
        overflow-x: hidden !important;
        height: 55vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

}
/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridDis{
        /* background-color: aqua; */
        -webkit-padding-start: 3%;
                padding-inline-start: 3%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadDis{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-top: 2%;
        text-align: center;
    }

    #segmentDis{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-bottom: 7%; */
    }

    #scrollDis{
        overflow-x: hidden !important;
        height: 60vh;
        overflow-y: 'scroll';
        -webkit-padding-start: 10%;
                padding-inline-start: 10%;
        -webkit-padding-end: 10%;
                padding-inline-end: 10%;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    #rowDis{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #firstParagraph{
        font-size: 17px;
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        /* text-align: justify; */
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
        line-height: 25px;
        text-justify: distribute;
        word-spacing: -2px;
        /* word-break: break-all; */
      }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #fontHeadDis{
      font-family: 'Mitr', sans-serif;
      font-size: 2.5vw;
      font-weight: 400;
      text-align: center;
    }
  
    #gridDis{
      margin-top: 5%;
      margin-left: 10%;
    }
  
    #segmentDis{
      /* background-color: aqua; */
      width: 80vw;
      height: 80vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 60vh;
      overflow-y: 'scroll';
      -webkit-padding-start: 5%;
              padding-inline-start: 5%;
      -webkit-padding-end: 5%;
              padding-inline-end: 5%;
      padding-top: 3%;
      padding-bottom: 3%;
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
    }
  
    #loadSegment{
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }

    #rowDis{
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    #firstParagraph{
        font-size: 17px;
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        text-align: justify;
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
        line-height: 25px;
        text-justify: distribute;
        word-spacing: -3px;
        /* word-break: break-all; */
      }
}
  
/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #fontHeadDis{
      font-family: 'Mitr', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin: 5%;
    }
  
    #gridDis{
      /* height: 80vh; */
      padding-left: 5%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
    }
  
    #segmentDis{
      /* background-color: aliceblue; */
      width: 90vw;
      height: 80vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 67vh;
      overflow-y: 'scroll';
      /* padding: 2% ; */
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
      -webkit-padding-start: 5%;
              padding-inline-start: 5%;
      -webkit-padding-end: 5%;
              padding-inline-end: 5%;
      padding-top: 3%;
      padding-bottom: 3%;
    }

    #firstParagraph{
        font-size: 17px;
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 16px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 10%;
        /* text-align: justify; */
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
        line-height: 25px;
        /* word-break: break-all; */
      }
  }

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #fontHeadDis{
        font-family: 'Mitr', sans-serif;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        margin: 5%;
    }
  
    #gridDis{
      /* background-color: aqua; */
      height: 80vh;
      height: 70vh; 
      z-index: 1;
      margin-top: 13%;      
      margin-left: 8%; 
    }
  
    #segmentDis{
      /* background-color: aliceblue; */
      width: 80vw;
      height: 70vh;
      border: 0;
      border-radius: 30px;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #scrollDis{
      overflow-x: hidden !important;
      height: 50vh;
      overflow-y: 'scroll';
      scroll-margin-top: 10%;
      scroll-margin-bottom: 10%;
      -webkit-padding-start: 10%;
              padding-inline-start: 10%;
      -webkit-padding-end: 10%;
              padding-inline-end: 10%;
      padding-top: 5%;
      padding-bottom: 3%;
    }

    #firstParagraph{
        font-size: 15px;
        -webkit-padding-start: 5%;
                padding-inline-start: 5%;
        text-align: justify;
    }

    #ansParagraph{
        font-size: 14px;
        font-family: 'Mitr';
        font-weight: 300;
        text-indent: 5%;
        /* text-align: justify; */
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
        line-height: 25px;
        /* word-spacing: -8px; */
      }
  }
  
