@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';

body {
    display: flex;
    font-family: 'Mitr', sans-serif;
    height: 100vh;
    display: inline-block;
  }
  
body, html, .App, #root {
    width: 100%;
    height: 100%;
    font-family: 'Mitr', sans-serif;
}
.container-fluid{
  padding-left: 0;
  padding-right: 0;
}
#pad{
    margin-left: 5%;
}

#modalProfile{  
  border-radius: 5vw; 
  text-align: center;
}

.ui.active.modal{
  position: sticky;
  height: fit-content;
}

#messageHeadModal{
  color: #386A48;
  font-family: 'Mitr', sans-serif;
  font-weight: 400;
  /* font-size: 1.2vw; */
  text-align: center;
  padding: 5%;
 }

#buttonPrize{
    font-family: 'Mitr', sans-serif;
    text-align: center;
    margin: 4%;
    border: 0;
    background-color: #FFD167;
    color: #935A10;
    border-radius: 20px;
}

#font{
  font-family: 'Mitr', sans-serif;
  box-shadow: 0px 5px 7px #79797988;
}

#fontPopup{
  font-family: 'Mitr', sans-serif;
}

#head-name{
  font-size: 1.5vw;
  font-family: 'Nunito', sans-serif;
  padding-top: 20px;
  color: #386A48;
  font-weight: 700;
}


#fontMenu{
  font-family: 'Mitr', sans-serif;
  font-size: 18px;
  color: #386A48;
  margin-left: 5px;
}

#menuActive{
  background-color: #688B73;
  color: #fff;
  font-weight: 500;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}

#menuInActive{
  color: #538061;
  font-weight: 500;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}

#lastMenuActive{
  background-color: #688B73;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 1px 0px 5.5px #478d2c; */
}


.popupDash.ui.popup:before{
  /* background: #CD5275 !important; */
  background: #ffffff !important;
}

#modalDesTree{
  padding: 2%;
}



/*  background on mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
  /* sidebar */
  #sideBarMobile{
    background-color: #DBFAA4;
    height: 101.5vh;
    width: 50vw;
    display: block;
    z-index: 5;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 3%;
    position: absolute;
    overflow-y: scroll;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }

  #itemSide{
    /* background-color: #935A10; */
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #386A48;
    margin-bottom: 5%;
  }
  
  #containnerMobile{
    margin-top: 5%;
    margin-left: 5%;
    height: fit-content;
    position: absolute;
  }

  #backgroundDash{
    background-image: url('./psyjaiDashPhonePort.png');
    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute'; 
  }

  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
    z-index: 4;
  }

  #gridCard{
    padding-top: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }

  #gridCalender{
    /* background-color: rgba(0, 128, 0, 0.253); */
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
  #calendar{ 
    width: 85vw;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 3%;

  }

    .fc{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
      width: 79vw;
      height: 70vh;
      
      /* rewrite button in calendar  */
      --fc-button-bg-color: #567e63;
      --fc-button-border-color: #567e63;
      --fc-button-hover-bg-color: #567e63;
      --fc-button-hover-border-color: #567e63;
      --fc-button-active-bg-color: #567e63;
      --fc-button-active-border-color: #567e63;
  
      /* rewrite color today */
      --fc-today-bg-color: #EEDBC9;
    }
  
    .fc-day a{
      color: #386A48;
    }
  
    .fc-header-toolbar{
      padding-top: 10px;

    }
  
    .fc-toolbar-title{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
    }
  
    #gridTreeDash{
      /* background-color: rgba(255, 255, 0, 0.247); */
      /* padding-left:vw ; */
      padding-top: 6vw;   
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center;
    } 
  
    #emotionContainner{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc-h-event .fc-event-main {
      /* background-color: #386A48; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
      /* background-color: #386A48; */
      /* height: 3vw; */
   }

    #imageEmotion{
      /* width: 4vw;
      height: 4vw; */
      align-self: center;
      justify-self: center;
    }
  
  
    #imgTree{
      /* justify-self: flex-end;   */
      width: 100vh; 
      /* height: 45vh;  */
    }
  
    #messageCard{
      border-radius: 100vw;
      width: 70vw;
      /* height: 5vh; */
      display: flex;
      justify-content: center;
      align-items: center;  
      padding: 8vw;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #messageTree{
      color: #386A48;
      font-family: 'Mitr', sans-serif;
      font-weight: 400;
      font-size: 3.5vw;
      text-align: center;
      padding: 2%;
    }
  
    #messageNameTree{
      border-radius: 100vw;
      /* background-color: #EFFCD1; */
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #labelTree{
      background-color: #567e63;
      width: 2vw;
      color: #fff;
      font-size: 3vw;
      margin-right: 30vw;
      box-shadow: 0px 2px 7px #79797988;
      /* margin-bottom: 2%; */
    }
  
    #textTree{
      border: 0;
      width: 65vw;
      height: 65vw;
      border-radius: 5vw;
      box-shadow: 0px 5px 7px #79797988;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding: 1.6vw; */
      /* align-items: center; */
      /* opacity: 0.20; */
    }
  

}

/*  background on mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
  #sideBarMobile{
    background-color: #DBFAA4;
    height: 101.5vh;
    width: 30vw;
    display: block;
    z-index: 5;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 3%;
    position: absolute;
    overflow-y: scroll;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
  }

  #itemSide{
    /* background-color: #935A10; */
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #386A48;
    margin-bottom: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  #containnerMobile{
    margin-top: 2%;
    margin-left: 5%;
    height: fit-content;
    position: absolute;
  }
  #backgroundDash{
    background-image: url('./psyjaiDashPhoneLand.png');
    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute';   
  }

  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
    padding-left: 10%;
    z-index: 3;
    
  }

  #gridCard{
    /* background-color: rgba(24, 24, 255, 0.836); */
    padding-top: 10%;
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: flex-start;
  }

  #gridCalender{
    /* background-color: rgba(0, 128, 0, 0.253); */
    width: 100%;
    padding-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
    
  #calendar{ 
    width: 100%;
    /* height: 120vh; */
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    /* padding: 2%; */

  }
  
    .fc{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
      width: 100%;
      height: 120vh; 
      padding: 2%;
      /* rewrite button in calendar  */
      --fc-button-bg-color: #567e63;
      --fc-button-border-color: #567e63;
      --fc-button-hover-bg-color: #567e63;
      --fc-button-hover-border-color: #567e63;
      --fc-button-active-bg-color: #567e63;
      --fc-button-active-border-color: #567e63;
  
      /* rewrite color today */
      --fc-today-bg-color: #EEDBC9;
    }
  
    .fc-day a{
      color: #386A48;
    }
  
    .fc-header-toolbar{
      padding-top: 10px;
    }
  
    .fc-toolbar-title{
      color: #386A48;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
    }

    .fc-scroller {
      overflow-y: hidden !important;
    } 
  
    #gridTreeDash{
      /* background-color: rgba(255, 255, 0, 0.247); */
      /* padding-left:vw ; */
      padding-top: 6vw;   
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center;
    } 
  
    #emotionContainner{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
      /* background-color: #386A48; */
      /* height: 3vw; */
   }
  
    .fc-h-event .fc-event-main {
      /* background-color: #386A48; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    #imageEmotion{
      width: 4vw;
      height: 4vw;
      align-self: center;
      justify-self: center;
    }
  
  
    #imgTree{
      /* justify-self: flex-end;   */
      width: 100vh; 
      /* height: 45vh;  */
    }
  
    #messageCard{
      border-radius: 100vw;
      width: 30vw;
      /* height: 22vh; */
      display: flex;
      justify-content: center;
      align-items: center;  
      padding: 3vw;
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #messageTree{
      color: #386A48;
      font-family: 'Mitr', sans-serif;
      font-weight: 400;
      font-size: 1.8vw;
      text-align: center;
      padding: 2%;
    }
  
    #messageNameTree{
      border-radius: 100vw;
      /* background-color: #EFFCD1; */
      box-shadow: 0px 5px 7px #79797988;
    }
  
    #labelTree{
      background-color: #567e63;
      width: 2vw;
      color: #fff;
      font-size: 1.5vw;
      margin-right: 30vw;
      box-shadow: 0px 2px 7px #79797988;
      /* margin-bottom: 2%; */
    }
  
    #textTree{
      border: 0;
      width: 30vw;
      height: 30vw;
      border-radius: 5vw;
      box-shadow: 0px 5px 7px #79797988;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding: 1.6vw; */
      /* align-items: center; */
      /* opacity: 0.20; */
    }
  
}


/* Medium devices (lanscape tablet and computer) */
@media only screen and (min-width: 900px){
  #containnerComputer{
    height: 100vh;
  }

  #backgroundDash{
    background-image: url('./psyjaiDashLandscape.png');
    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;    
    justify-content: center;
    align-items: center;
    padding-left: 6%;
  }

  #gridMenu{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 20%; */
    align-items: center;
  }

  #gridDashboard{
    /* padding-inline: 5%; */
    padding-inline-start: 5%;
    height: 80vh;
    width: 80vw;
  }

  #gridCard{
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }

  #logout{
    border: 0;
    border-radius: 10px;
    width: fit-content;
    padding-bottom: 10px;
    font-family: 'Mitr', sans-serif;
    font-weight: 500;
    background-color: #fff;
    color: rgb(54, 54, 54);
    box-shadow: 0px 5px 7px #79797988;
  }
  
  /* css calendar */
  #gridCalender{
    /* background-color: green; */
    width: fit-content;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  #calendar{ 
    width: 50vw;
    /* height: 75vh; */
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 2vw;
  }

  .fc{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    width: 45vw; 
    height: 70vh;
    
    /* rewrite button in calendar  */
    --fc-button-bg-color: #567e63;
    --fc-button-border-color: #567e63;
    --fc-button-hover-bg-color: #567e63;
    --fc-button-hover-border-color: #567e63;
    --fc-button-active-bg-color: #567e63;
    --fc-button-active-border-color: #567e63;
    
    /* rewrite color today */
    --fc-today-bg-color: #EEDBC9;
    
  }
  .fc .fc-daygrid-day.fc-day-today{
    /* background-color: #fcfcfc; */
    /* border-color: #386A48; */
  }

  .fc-day a{
    color: #386A48;
  }

  .fc-header-toolbar{
    padding-top: 10px;
  }

  .fc-toolbar-title{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
     /* background-color: #386A48; */
     /* height: 3vw; */
  }

  #imageEmotion{
    width: 4vw;
    height: 4vw;
    align-self: center;
    justify-self: center;
  }
  
  .fc-h-event .fc-event-main {
    /* background-color: #ac917cdc; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  td {
    /* height: 20px; */
  }

  #gridTreeDash{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #emotionContainner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #386A48;
    height: 4vh;
  }

  
  #imgTree{
    /* justify-self: flex-end;   */
    width: 90vh; 
    /* height: 45vh;  */
  }

  #messageCard{
    border-radius: 100vw;
    width: 20vw;
    /* height: 10vh; */
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 2.5vw;
    box-shadow: 0px 5px 7px #79797988;
  }

  #messageTree{
    color: #386A48;
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    padding: 2%;
  }

  #messageNameTree{
    border-radius: 100vw;
    /* background-color: #EFFCD1; */
    box-shadow: 0px 5px 7px #79797988;
  }

  #labelTree{
    background-color: #567e63;
    width: 2vw;
    color: #fff;
    font-size: 1vw;
    margin-right: 30vw;
    box-shadow: 0px 2px 7px #79797988;
    /* margin-bottom: 2%; */
  }

  #textTree{
    border: 0;
    width: 19vw;
    height: 19vw;
    border-radius: 5vw;
    box-shadow: 0px 5px 7px #79797988;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

 #rowAvatar{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 2%;
 }

 #messageHeadModal{
  color: #386A48;
  font-family: 'Mitr', sans-serif;
  font-weight: 400;
  /* font-size: 1.2vw; */
  text-align: center;
  padding: 5%;
 }

 #imageAvatarActive{
    background-color: #DBFAA4;
    border-radius: 20vw;
    padding: 2%;  
    width: fit-content;
 }

 #imageAvatar{
  /* background-color: #DBFAA4; */
  border-radius: 20vw;
  padding: 2%;  
  width: fit-content;
}

#imgLoading{
  /* background-color: #386A48; */
  width: 16vw;
  height: 16vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
}

#buttonChange{
  /* width: 12.8vw; */
  display: flex;
  /* background-color: #c0e4cb; */
  padding: 0;
  /* position: absolute; */
  z-index: 1;
  justify-content: flex-end;
}

#nameContainner{
  /* background-color: #386A48; */
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
 #buttonModal{
  font-weight: 400;
  border-radius: 2vw; 
  font-family: 'Mitr', sans-serif;
 }
} 


/* Medium devices (portrait tablet) */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
  #containnerTablet{
    height: 100vh;
    position: absolute; 
  }

  #backgroundDash{
    background-image: url('./psyjaiDashLandscape.png');
    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: 'absolute'; 
  }

  /* rewrite */
  #gridMenu{
    /* background-color: rgb(255, 233, 233); */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10%;
    padding-left: 10%;
    z-index: 3;
    /* position: absolute; */
  }

  #gridCard{
    /* background-color: rgba(24, 24, 255, 0.836); */
    width: 100vw;
    margin-left: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    z-index: 2;
    /* position: relative; */
  }

  #gridCalender{
  /* background-color: rgba(0, 128, 0, 0.253); */
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* position: relative; */
  }
  
  #calendar{ 
    width: 80vw;
    height: 80vw;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
    padding: 2vw;
  }

  .fc{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    width: 55vw; 
    height: 65vh;
    
    /* rewrite button in calendar  */
    --fc-button-bg-color: #567e63;
    --fc-button-border-color: #567e63;
    --fc-button-hover-bg-color: #567e63;
    --fc-button-hover-border-color: #567e63;
    --fc-button-active-bg-color: #567e63;
    --fc-button-active-border-color: #567e63;

    /* rewrite color today */
    --fc-today-bg-color: #EEDBC9;
  }

  .fc-day a{
    color: #386A48;
  }

  .fc-header-toolbar{
    padding-top: 10px;
  }

  .fc-toolbar-title{
    color: #386A48;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }

   #gridTreeDash{
    /* background-color: rgba(255, 255, 0, 0.247); */
    /* padding-left:vw ; */
    padding-top: 6vw;   
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    position: relative;
  } 

  #emotionContainner{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc-h-event .fc-event-main {
    /* background-color: #386A48; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    /* background-color: #386A48; */
    /* height: 3vw; */
 }

  #imageEmotion{
    /* width: 4vw;
    height: 4vw; */
    align-self: center;
    justify-self: center;
  }


  #imgTree{
    /* justify-self: flex-end;   */
    width: 100vh; 
    /* height: 45vh;  */
  }

  #messageCard{
    border-radius: 100vw;
    width: 25vw;
    /* height: 10vh; */
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 3vw;
    text-align: center;
    box-shadow: 0px 5px 7px #79797988;
  }

  #messageTree{
    color: #386A48;
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 1.8vw;
    text-align: center;
    padding: 2%;
  }

  #messageNameTree{
    border-radius: 100vw;
    /* background-color: #EFFCD1; */
    box-shadow: 0px 5px 7px #79797988;
  }

  #labelTree{
    background-color: #567e63;
    width: 2vw;
    color: #fff;
    font-size: 1.2vw;
    margin-right: 30vw;
    box-shadow: 0px 2px 7px #79797988;
    /* margin-bottom: 2%; */
  }

  #textTree{
    border: 0;
    width: 24vw;
    height: 24vw;
    border-radius: 5vw;
    box-shadow: 0px 5px 7px #79797988;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 1.6vw; */
    /* align-items: center; */
    /* opacity: 0.20; */
  }

} 