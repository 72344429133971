/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridMood{
        padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;  
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        margin-inline: 10%;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: flex;
        justify-content: center;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        display: flex;
        justify-content: center;
    }

    #segmentMood{
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        align-items: center;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
      
        /* padding: 2% ; */
    }

    #centered{
        display: 'flex'; 
        justify-content: 'center';
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridMood{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        margin-inline: 4.8%;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
    }

    #segmentMood{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 50vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: flex;
        justify-content: center;
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridMood{
        margin: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        margin-inline-start: 4.8%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentMood{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        padding-inline-start: 4%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        display: flex;
        flex-direction: column;
        /* padding: 2% ; */
    }    

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridMood{
        height: 70vh; 
        margin-top: 13%;      
        margin-left: 8%; 
        display: flex;
        justify-content: center; 
        align-items: center;
        z-index: 1;
    }

    #fontMessage{
        font-family: 'Mitr', sans-serif;
        font-weight: 400;
    }

    #showMessage{
        /* margin-inline: 4.8%; */
        /* background-color: antiquewhite; */
        width: 40vw;
        margin: 4%;
    }

    #moodContainner{
        /* background-color: antiquewhite; */
        padding-top: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #fontHeadMood{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentMood{
        width: 80vw;
        height: 65vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        padding-inline-start: 4%;
    }

    #scrollMood{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        /* padding: 2% ; */
    }

    #rowDropdown{
        /* background-color: antiquewhite; */
        display: flex;
        justify-content: center;
    }
}