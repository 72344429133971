@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap');

body {
    display: flex;
    font-family: 'Mitr', sans-serif;
    /* min-height: 100vh; */
    display: flex;
   
}
  
body, html, .App, #root {
    width: 100%;
    height: 100%;
}

.kep-login-facebook.metro{
    border-radius: 15px;
    font-family: 'Mitr';
    font-weight: 400;
    box-shadow: 0px 3px 10px #88888879;
    /* background-color: #3b5998; */
}

/* #### Desktops #### */
@media screen and (min-width: 900px){
    /* some CSS here */
    #background{
        background-image: url('./webBGTest.png');
        /* background-color: #0f8970; */
        height: 100vh;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        justify-content: center;
    }
    
    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 8vw;
        color: #0f8970; 
    }
    
    #psyjaiContainner{
        margin-top: 20vw;
        justify-content: center;
        text-align: center;
    }
    
    .signInbutton{
        font-family: 'Nunito', sans-serif;
        color: white;
        background-color: #3b5998;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px #88888879;
        
    }
   
}



/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 480px){
    /* some CSS here */
    #background{
        background-image: url('./psyjaiPhoneBG.png');
        height: 100vh;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        justify-content: center;
    
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 18vw;
        color: #0f8970;
        justify-content: center;
    }
    
    #psyjaiContainner{
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 30vh;
    }
    
    #signInbutton{
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px #88888879;
      
    }
}

/* #### Mobile Phones Landscape #### */
@media screen and (min-width: 480px) and (max-width: 900px) and (orientation: landscape){
    /* some CSS here */
    #background{
        background-image: url('./psyjaiPhoneland.png');
        /* background-color: #0f8970; */
        height: 100vh;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        justify-content: center;   
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 10vw;
        color: #0f8970;
        justify-content: center;
    }
    
    #psyjaiContainner{
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25vh;
    }
    
    #signInbutton{
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px #88888879;
      
    }
}


/* #### Tablets Portrait or Landscape #### */
@media screen and (min-width: 480px) and (max-width: 900px) and (orientation: portrait){
    /* some CSS here */
    #background{
        background-image: url('./psyjaiTabletport.png');
        /* background-color: #0f8970; */
        height: 100vh;
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        position: 'absolute';
        justify-content: center;
    }

    #headPsyjai{
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        text-align: center;
        font-size: 12vw;
        color: #0f8970;
        justify-content: center;
    }
    
    #psyjaiContainner{
        justify-content: center;
        text-align: center;
        margin-top: 30vw;
    }
    
    #signInbutton{
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 10px #88888879;
        width: 10px;
        height: 300px;
      
    }
  }
