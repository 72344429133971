/* tablet landscape and computer */
@media only screen and (min-width: 900px){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 1.8vw;
    font-weight: 400;
    margin-left: 3%;
  }

  #gridIntervention{
    /* background-color: aqua; */
    /* padding-inline: 5%; */
    padding-inline-start: 5%;
    height: 80vh;
    width: 80vw;
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 76vh;
    overflow-y: 'scroll';
    padding: 2% ;
  }

  #loadSegment{
    width: fit-content;
  }

  .ui.popup:before {
    /* background: #CD5275 !important; */
    background: #CD5275 !important;
  }

  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 2.5vw;
    font-weight: 400;
  }

  #gridIntervention{
    margin-top: 5%;
    margin-left: 10%;
  }

  #segmentIntervention{
    /* background-color: aqua; */
    width: 80vw;
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 70vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: fit-content;
  }
  #popup{
    /* border: 0; */
    /* opacity: 1;
    padding: '1em'; */
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}

}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 4vw;
    font-weight: 400;
  }

  #gridIntervention{
    /* height: 80vh; */
    padding-left: 5%;
    display: flex;
    align-items: center;
    /* background-color: aliceblue; */
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    width: 90vw;
    height: 80vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 76vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: fit-content;
  }
  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}

}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
  #fontHeadIntervention{
    font-family: 'Mitr', sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
    padding-top: 4%;
    padding-left: 2%;
  }

  #gridIntervention{
    /* background-color: aqua; */
    height: 80vh;
    height: 70vh; 
    z-index: 1;
    margin-top: 13%;      
    margin-left: 8%; 
  }

  #segmentIntervention{
    /* background-color: aliceblue; */
    width: 80vw;
    height: 70vh;
    border: 0;
    border-radius: 30px;
    box-shadow: 0px 5px 7px #79797988;
  }

  #scrollIntervention{
    overflow-x: hidden !important;
    height: 65vh;
    overflow-y: 'scroll';
    padding: 2% ;
    scroll-margin-top: 10%;
    scroll-margin-bottom: 10%;
  }

  #loadSegment{
    width: fit-content;
  }
  #popup{
    border: 0;
    opacity: 1;
    padding: '1em';
    background-color: '#FBE0E8'
  }
  .ui.popup:before {
    background: #CD5275 !important;
}
}