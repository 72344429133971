/* tablet landscape and computer */
@media only screen and (min-width: 900px){
    #gridTree{
        /* background-color: aqua; */
        /* padding-inline: 5%; */
        padding-inline-start: 5%;
        height: 80vh;
        width: 80vw;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.8vw;
        font-weight: 400;
        margin-left: 3%;
        margin-top: 2%;
        /* padding-left: 2%; */
    }

    #segmentTree{
        width: 70vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 76vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}

/* mobile lanscape */
@media only screen and (max-width: 900px) and (orientation: landscape){
    #gridTree{
        margin-top: 5%;
        margin-left: 10%;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 2.5vw;
        font-weight: 400;
        padding: 1.2%;
    }

    #segmentTree{
        width: 80vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 55vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}

/* mobile portrait */
@media only screen and (max-width: 480px) and (orientation: portrait){
    #gridTree{
        padding-left: 5%;
        display: flex;
        align-items: center;
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 4vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentTree{
        width: 90vw;
        height: 80vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        padding-inline-start: 4%;
    }

    #scrollTree{
        overflow-x: scroll !important;
        height: 65vh;
        overflow-y: scroll;
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

/* tablet portrait */
@media only screen and (max-width: 900px) and (min-width: 480px) and (orientation: portrait) {
    #gridTree{
        /* height: 80vh; */
        height: 70vh; 
        z-index: 1;
        margin-top: 13%;      
        margin-left: 8%; 
    }

    #fontHeadTree{
        font-family: 'Mitr', sans-serif;
        font-size: 3.5vw;
        font-weight: 400;
        padding-top: 4%;
        padding-left: 2%;
    }

    #segmentTree{
        width: 80vw;
        height: 70vh;
        border: 0;
        border-radius: 30px;
        box-shadow: 0px 5px 7px #79797988;
        padding: 2%;
        /* padding-inline: 4%; */
        padding-inline-start: 4%;
    }

    #scrollTree{
        overflow-x: hidden !important;
        height: 65vh;
        overflow-y: 'scroll';
        padding: 2% ;
    }

    #nameHeaderTree{
        font-family: 'Mitr', sans-serif;
        font-size: 1.5vw;
        font-weight: 400;
    }
}